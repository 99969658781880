@import "./global.scss";

@font-face {
  font-family: "Montserrat";
  src: url("./../assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./../assets/fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("./../assets/fonts/HelveticaNeueRegular.woff2") format("woff2"),
    url("./../assets/fonts/HelveticaNeueRegular.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  src: url("./../assets/fonts/FontAwesome5FreeRegular.woff2") format("woff2"),
    url("./../assets/fonts/FontAwesome5FreeRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue Bold";
  src: url("./../assets/fonts/HelveticaNeue-Bold.woff2") format("woff2"),
    url("./../assets/fonts/HelveticaNeue-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Compact Display";
  src: url("./../assets/fonts/SFCompactDisplay-Semibold.woff2") format("woff2"),
    url("./../assets/fonts/SFCompactDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $background-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-color: $background-color;
}
* {
  box-sizing: border-box;
}

// utils
.text-center {
  text-align: center;
}
.flex-start {
  @include flex-start;
}
.flex-end {
  @include flex-end;
}
.flex-between {
  @include flex-between;
}
.flex-all {
  @include flex-all;
}
.flex-column {
  flex-direction: column;
}
.align-start {
  align-items: flex-start;
}
.flex-1 {
  flex: 1;
}
.p-right-50 {
  padding-right: 50px;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.all-100 {
  width: 100%;
  height: 100%;
}
.top--100 {
  top: -100%;
}
.display-none {
  display: none;
}

.two-group-wrapper {
  & > div:first-child,
  & > button:first-child {
    margin-right: 227px;
  }
}
.flex-all {
  @include flex-all;
  width: 100%;
}
.select-none {
  user-select: none;
}
.absolute-bottom {
  position: absolute;
  bottom: 0;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}
.absolute-top {
  position: absolute;
  top: 0;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}

/**
  FORM
*/
.one-sized {
  @include one-sized;
}
.main-label {
  @include flex-start;
  font-family: $latto;
  font-size: 16px;
  line-height: 19px;
}
.sec-label {
  @include flex-start;
  font-family: $latto;
  font-size: 12px;
  line-height: 15px;
}
.input-group {
  @include flex-start;
  margin-top: 26px;
  .input-group-label {
    padding-right: 20px;
    color: $dark-grey;
    input {
      width: 144px;
    }
  }
}

/*
 label and boxes
*/
.red-note {
  @include flex-all;
  background-color: $red-alert;
  width: 100%;
  padding-bottom: 14px;
  padding-top: 5px;
  margin-top: 12px;
  span {
    font-family: $latto;
    font-size: 16px;
    line-height: 14px;
    color: $white;
  }
}

