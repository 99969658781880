@import "./../../../styles/global.scss";

.plant-container {
  position: relative;
  background: $green-primary;
  color: white;
  min-width: 323px;
  min-height: 323px;
  @include flex-all;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 12.5px;
  margin-bottom: 25px;
  border-radius: 3px;
  font-family: $helvetica-neue;
  padding-top: 52px;
  
  .plant-container-title {
    font-size: 36px;
    line-height: 41px;
  }
  .plant-container-subtitle {
    font-size: 18px;
    line-height: 14px;
  }
  .plant-vessel-icon {
    padding-bottom: 36px;
  }
  .question-mark-wrapper {
    position: absolute;
    right: 0;
    top: 0;
  }
}
