@import "../../../styles/global.scss";

.cart-build-line {
  @include flex-start;
  $height-cart-build-line: 43px;
  width: 100%;
  border-top: 1px solid $very-light-grey;
  height: $height-cart-build-line;
  padding-right: 17px;
  .cart-build-line-inner {
    width: 100%;
    @include flex-start;
    .icon-wrapper {
      @include flex-all;
      width: $height-cart-build-line;
      height: $height-cart-build-line;
    }
    .input-wrapper {
      padding-right: 12px;
    }
    .label-wrapper {
      flex: 1;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 12px;
      line-height: 15px;
      font-family: $latto;
      color: $dark-grey;
    }
    .price-wrapper {
      min-width: 40px;
      font-family: $latto;
      .price-label {
        color: $dark-grey;
        font-size: 10px;
        line-height: 12px;
      }
      .price-amount {
        color: $green-success;
        font-size: 16px;
        line-height: 24px;
      }
    }

  }
}
