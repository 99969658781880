@import "../../../styles/global.scss";

.text-indicator {
  display: flex;
  .indicator-icon {
    @include flex-all;
    height: 17px;
    width: 32px;
  }
  .text-label {
    font-family: $latto;
    font-size: 12px;
    line-height: 15px;
    color: $dark-grey;
  }
}

