@import "./../../../styles/global.scss";

.question-mark-wrapper {
    @include flex-all;
    width: 21px;
    height: 21px;
    border-radius: 50px;
    background-color: $white;
    margin: 10px;
    color: $green-primary;
    font-family: $helvetica-neue;
}