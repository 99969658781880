@import "./../../../styles/global.scss";

.number-selection {
   padding-top: 13px;
  .number-selection-inner {
    $n-control-color: #e2e2e2;
    $n-input-color: #f2f2f2;
    $n-border-color: $light-grey;

    display: flex;
    height: 27px;
    border: 1px solid $n-border-color;
    .number-selection-control {
      @include flex-all;
      height: 100%;
      background-color: $n-control-color;
      width: 27px;
      font-family: $Montserrat;
      color: black;
      cursor: pointer;
      font-size: 13px;
    }
    & > .number-selection-control:first-child {
        border-right: 1px solid $n-border-color;
    }
    & > .number-selection-control:last-child {
        border-left: 1px solid $n-border-color;
    }
    .number-selection-value {
      @include flex-all;
      height: 100%;
      background-color: $background-color;
      width: 36px;
      font-size: 13px;
      font-family: $Montserrat;
      color: black;
    }
  }
}
