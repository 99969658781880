@import "../../../styles/global.scss";

.custom-button {
  /* Clear default styling */
  color: white;
  border: 0px;

  /* Custom styling */
  background: $green-primary;
  border-radius: 3px;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  padding: 18px 85px;
  cursor: pointer;
  font-family: $Montserrat;
  &.primary {
    background: $green-primary;
    &.success {
      border: 1px solid $green-success;
      background: $green-success;
      color: $white;
    }
  }
  &.secondary {
    background: $secondary-button;
  }
  &.outline {
    border: 1px solid $green-primary;
    color: $green-primary;
    background: transparent;
    background-color: transparent;
    &.pale {
      border: 1px solid $dark-grey;
      color: $dark-grey;
    }
    &.success {
      border: 1px solid $green-success;
      color: $green-success;
    }
  }
  &.variant-1 {
    height: 45px;
    font-size: 16px;
    line-height: 19px;
    padding: 0 17px;
    @include flex-all;
    min-width: 124px;
    font-family: $latto;
  }
  &.forward {
    &::after {
        content: url(./../../../assets/svg/ctrl-right.svg); 
        margin-left: 10px;
        width: 6.79px; 
        height: 18.67px;
    }
  }
}
