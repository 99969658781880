@import "./../../../styles/global.scss";

.plant-kit {
  background: $green-primary;
  color: white;
  min-width: 236px;
  min-height: 236px;
  @include flex-all;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 17px;
  margin: 0 12.5px;
  margin-bottom: 25px;
  cursor: pointer;
  border-radius: 3px;
  font-family: $helvetica-neue;
  .plant-kit-title {
    font-size: 36px;
    line-height: 41px;
  }
  .plant-kit-subtitle {
    margin-top: 15px;
    font-size: 24px;
    line-height: 48px;
  }
  .weed-icon {
    padding-bottom: 36px;
  }
}
