@import "./../../../styles/global.scss";

.my-gallery-wrapper {
  width: 100%;
  padding-top: 100px;
  
  .image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1.5px;
    grid-auto-rows: 10px;
    width: 100%;
  }
  
  .image-list {
    img {
      width: 250px;
    }
  }
  .gallery-footer {
      border-top: 1px solid $title-color;
      padding-top: 40px;
      margin-top: 40px;
  }
}
