@import "./../../../styles/global.scss";

.cart-page {
  @mixin cart-page-page-height {
    height: calc(100vh - #{$header-height});
  }
  @include cart-page-page-height;
  position: relative;
  $cart-aside-width: 66px;
  $cart-page-footer-height: 55px;
  .is-cart-page {
    height: calc(100vh - #{$header-height + $cart-page-footer-height});
    overflow: auto;
  }
  .cart-aside {
    @include flex-start;
    align-items: flex-start;
    width: 100vw;
    max-width: $cart-aside-width;
    height: 100%;
    overflow: hidden;
    position: fixed;
    left: 0;
    z-index: 12;
    
    .aside-item-title {
      @include flex-between;
      align-items: flex-start;
      width: 100%;
      font-family: $latto;
      color: $dark-grey;
      font-size: 16px;
      padding-top: 20px;
    }
    .aside-left-menu {
      height: 100%;
      border-right: 1px solid $light-grey-sel;
      background-color: $background-color;
      .aside-hamburger {
        @include flex-all;
        width: $cart-aside-width;
        height: $cart-aside-width;
        background-color: $dark-grey;
        position: relative;
        .aside-hamb-cover {
          position: absolute;
          top: 0;
          right: -1px;
          background-color: $dark-grey;
          width: 5px;
          height: 100%;
        }
      }
      .aside-icon-item {
        width: $cart-aside-width;
        height: $cart-aside-width;
        .aside-icon {
          @include flex-start;
          flex-direction: column;
          width: 100%;
          height: 40px;
          padding-top: 10px;
          padding-bottom: 5px;
        }
        .aside-icon-title {
          font-family: $latto;
          font-size: 7.5px;
          line-height: 9px;
          color: $green-primary;
          width: 100%;
          text-align: center;
        }
        border-bottom: 1px solid $light-grey-sel;
        &.aside-selected {
          background-color: $light-grey-sel;
        }
      }
    }
    .aside-right-menu {
      @include flex-start;
      @include cart-page-page-height;
      width: calc(100vw - #{$cart-aside-width});
      background-color: rgba(0, 0, 0, 0);
      .aside-right-inner {
        $aside-right-menu-shadow: 13px 32px 93px -14px rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: $aside-right-menu-shadow;
        -moz-box-shadow: $aside-right-menu-shadow;
        box-shadow: $aside-right-menu-shadow;

        background-color: $background-color;
        overflow: auto;
        width: 935px;
        $aside-right-footer-height: 55px;
        @include flex-between;
        flex-direction: column;
        overflow: auto;
        height: 100%;
        .aside-right-header {
          @include flex-all;
          justify-content: space-between;
          height: $cart-aside-width;
          font-size: 20px;
          color: $white;
          font-family: $latto;
          font-weight: bold;
          width: 100%;
          background-color: $dark-grey;
          line-height: 24px;
          .close-icon {
            width: $cart-aside-width;
            height: $cart-aside-width;
            @include flex-all;
          }
          span {
            flex: 1;
            text-align: center;
          }
        }
        .aside-right-subtitle {
          @include flex-all;
          padding-right: $cart-aside-width;
          font-size: 16px;
          color: $white;
          font-family: $latto;
          font-weight: bold;
          width: 100%;
          background-color: $green-primary;
          line-height: 35px;
          height: 35px;
        }
        @mixin defautContentLayout {
          flex: 1;
          width: 100%;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
        }
        @mixin cart-build-line-config {
          .build-lines-container {
            width: 100%;
            display: flex;
            align-content: flex-start;
            flex-wrap: wrap;

            justify-content: space-between;

            .build-line-wrapper {
              width: 396px;
              height: 43px;
              max-width: 100%;
            }
          }
          .build-lines-borders {
            @include flex-between;
            width: 100%;
            .border {
              width: 396px;
              height: 1px;
              border-top: 1px solid $very-light-grey;
            }
          }
        }
        .aside-right-content {
          @include defautContentLayout;
          /*
          start - ###CONTENT PAGES
        */
          .room-style {
            $iput-row-mg: 26px;
            flex: 1 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            min-height: 480px;
            .flex-col {
              &.padding-right {
                padding-right: 55px;
              }
            }

            .input-group {
              .second-col {
                width: 140px;
              }
              .flex-col {
                & > div,
                & > input {
                  margin-bottom: $iput-row-mg;
                }
              }
              input {
                width: 144px;
                box-sizing: border-box;
              }
            }

            .tiers-per-zone {
              .main-label {
                margin-bottom: 12px;
              }
              .sec-label {
                margin-top: 2px;
              }
              .counter-lines {
                width: 120px;
                padding-left: 17px;
                @include flex-all;
                flex-direction: column;
                height: 38px;
                .tier-line {
                  @include flex-all;
                  flex-direction: column;
                  width: 100%;
                  & > div {
                    width: 100%;
                    background-color: $green-primary;
                    height: 4px;
                    border: 1px solid $idle-grey;
                  }
                  padding-bottom: 5px;
                }
                & > .tier-line:last-child {
                  padding-bottom: 0;
                }
              }
            }

            .dropdown-room {
              .single-flex-inner {
                @include flex-all;
                & > div:first-child {
                  margin-right: 65px;
                }
                .dropdown-left {
                  width: 345px;
                }
              }
            }

            .room-act-btns {
              flex: 1;
              @include flex-start;
              justify-content: flex-end;
              flex-direction: column;
              .single-flex-inner {
                @include flex-start;
                & > button:first-child {
                  margin-right: 7px;
                }
              }
              padding-bottom: 50px;
            }
          }
          .components-aside {
            @include defautContentLayout;
            @include cart-build-line-config;
            .build-lines-container {
              flex: 1;
              height: 420px;
              padding-bottom: 75px;
              padding-top: 30px;
            }
          }
          .nutrients-aside {
            @include defautContentLayout;
            @include cart-build-line-config;

            .build-lines-container {
              height: calc(
                172px + 16px
              ); //height + padding top (padding works better than mg in flexbox)
              padding-top: 16px;
            }
          }
          /*
          end - ###CONTENT PAGES
        */
        }
        .aside-content-footer {
          @include flex-end;
          width: 100%;
          height: $aside-right-footer-height;
          background-color: $thin-yellow;
          .aside-total-item {
            @include flex-all;
            height: 100%;
            flex-direction: column;
            padding: 0 8px;
            font-family: $latto;
            .total-label {
              color: $dark-grey;
              font-size: 16px;
              line-height: 19px;
            }
            .total-amount {
              color: $green-success;
              font-size: 20px;
              line-height: 15px;
            }
          }
        }
      }
    }

    &.-cart-aside-expanded {
      max-width: 100vw;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  .cart-page-footer {
    width: 100vw;
    padding-left: $cart-aside-width;
    .checkout-text {
      @include generic-latto-16;
      color: white;
    }
    .cart-page-footer-inner {
      @include flex-end;
      width: 100%;
      height: 55px;
      background-color: $thin-yellow;
      .cart-footer-checkout-save {
        @include flex-all;
        height: 100%;
        padding: 0 25px;
        background-color: $blue-constrast;
        .checkout-text {
          &::after {
            content: url(./../../../assets/svg/save.svg);
            margin-left: 12px;
          }
        }
      }
      .cart-footer-checkout {
        @include flex-start;
        height: 100%;
        padding: 0 25px;
        background-color: $green-success;
        position: relative;
        &::after {
          content: url(./../../../assets/svg/ctrl-right-small.svg);
          position: absolute;
          right: 10px;
        }
        .checkout-icon {
          padding-right: 20px;
        }
        .checkout-text {
          padding-right: 20px;
        }
        .checkout-amount {
          @include generic-montserrat-16;
          color: white;
          font-weight: bold;
          padding-right: 20px;
        }
      }
      .cart-footer-act-icon {
        @include flex-all;
        
        cursor: pointer;
        padding: 0 12px;
      }
      &.cart-board-is-loading {
        .cart-footer-act-icon {
          opacity: 0.7;
          cursor: unset;
        }
      }
    }
  }
}
