@import "../../../styles/global.scss";

.custom-input {
  /* Remove basic styling */
  display: block;
  padding: 14px 15px 16px 15px;
  border: 0px;

  /* Layout-based Styling */
  color: $input-color;
  background: $input-background;
  border-radius: 3px;
  font-size: 12px;
  line-height: 14px;
  min-width: 300px;
  font-family: $Montserrat;
  .custom-input::placeholder {
    color: $input-color;
  }
  .custom-input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &.variant-outline {
    font-size: 16px;
    color: black;
    height: 38px;
    background-color: $white;
    border-radius: 5px;
    font-family: $latto;
    border: 1px solid $input-color;
    padding: 0 16px;
    min-width: unset;
  }
  &.variant-min {
    width: 43px;
    height: 32px;
    border-color: transparent;
    font-family: $latto;
    font-size: 15px;
    line-height: 15px;
    color: $dark-grey;
    background-color: $thin-yellow;
    border-radius: 5px;
    min-width: unset;
    text-align: center;
    padding: 0 10px;
  }
}

