@import "./../../../../styles/global.scss";
.cart-board {
  @include flex-start;
  flex-direction: column;
  width: 100%;
  background-image: url(./../../../../assets//image/cart-background.png);
  background-size: 100% 100%;
  position: relative;

  .room-switcher {
    width: 100%;
    display: flex;
    @include flex-all;
    padding-top: 6px;
    padding-bottom: 52px;
  }
  .room-2d-wrapper {
    background-color: $white;
    
    $room--2d-light-grey: #d8d6d7;
    $room-2d-wrapper-padding: 40px;
    $room-2d-wrapper-border-width: 20px;
    $zone-2d--middle-height: 7px;
    $zone-2d--right-tube-thickness: 11px;

    border: $room-2d-wrapper-border-width solid black;
    padding-left: $room-2d-wrapper-padding;
    padding-right: $room-2d-wrapper-padding;
    /*
    solves the problem with lower right corner white
    covering line being 1 px smaller on a room with one zone
    */
    padding-bottom: #{$room-2d-wrapper-padding + 1};

    position: relative;

    & > .zone-2d-square {
      margin-top: $room-2d-wrapper-padding;
    }
    & > .zone-2d-square:first-child {
      margin-top: 0;
    }
    
    $zone-2d-overall-height: 412px;
    $zone-2d-overall-height-st3: 358px;
    $zone-2d-overall-height-st5: 358px;

    .zone-2d-square {
      @include flex-all;
      $zone-half-size: 125px;
      $zone-2d--tile-width: 80px;
      $zone-2d-tile-height: 60px;

      width: 100%;
      height: $zone-2d-overall-height;
      border: 2px solid black;

      .tile-linkage {
        width: 16px;
        height: 16px;
        border: solid 1px black;
        border-radius: 16px;
        background-color: $green-primary;
      }

      .zone-2d--middle {
        position: relative;
        width: 100%;
        height: $zone-2d--middle-height;

        .zone-2d--middle-content,
        .zone-2d--half {
          height: $zone-half-size;
          width: 100%;
          position: absolute;
          .zone-2d-row-wrapper {
            @include flex-all;
            flex-direction: column;
            .zone-2d-plant-row {
              display: flex;
            }
          }
        }
        .zone-2d--middle-content {
          top: #{- ($zone-half-size / 2 - 4)};
          @include flex-all;
          .zone-2d-row-wrapper {
            width: 100%;
            position: relative;
            .zone-2d-plant-row-for-width {
              display: flex;
              position: relative;
            }
            .zone-2d-under-tube {
              position: absolute;
              width: 100%;
              height: $zone-2d--middle-height;
              top: 3px;
            }
          }
        }
        .-horizontal-flipped {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
        .-tile-margin-left {
          margin-right: 44px;
        }
        .zone-2d--upper {
          bottom: calc(100% - 1px);
        }
        .zone-2d--bottom {
          top: calc(100% - 1px);
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
        }
        .zone-2d--tile {
          width: $zone-2d--tile-width;
          height: $zone-2d-tile-height;
          @include flex-all;
          position: relative;
          .tile-img {
            position: absolute;
            max-width: 80px;
            top: 1px;
          }
          /*
            #style1
            #style2 - as well
          */
          &.tile-1-long {
            .tile-img {
              top: -21px;
            }
          }
          &.tile-1-short {
            .tile-img {
              top: 1px;
            }
          }
          
          /*
            #style3
            97 .. 100
            82
          */
          &.tile-3-long {
            .tile-img {
              top: unset;
              bottom: -0.4px;
              max-width: 82px;
              left: -2px;
            }
          }
          &.tile-3-short {
            .tile-img {
              bottom: 23.9px;
              top: unset;
              left: -2px;
              width: 67.62px;
            }
          }
          &.zone-2d--tile-linkage {
            z-index: 9;
            .tile-linkage-wrapper {
              position: absolute;
              right: -8px;
              bottom: 0;
            }
          }
          &.-for-width-spacing {
            height: $zone-2d--middle-height;
          }
        }
      }
    }

    /**
      this take all room height and gets covered with bg color in the lower right corner 
      by .zone-2d-under-tube-right-exceed-last-zone (in style-1)
    */
    .room-2d-right-tube {
      width: $zone-2d--right-tube-thickness;
      height: 100%;
      background-color: $room--2d-light-grey;
      position: absolute;
      right: 0;
      top: 0;
    }

    .green--corner {
      display: flex;
      .v-lower-corner {
        height: #{$zone-2d--right-tube-thickness + 3};
        width: $zone-2d--right-tube-thickness;
      }
      .h-lower-corner {
        width: 7px;
        padding-top: 4px;
      }
      .green-100 {
        background-color: $green-primary;
        width: 100%;
        height: 100%;
      }
      &.absolute-bottom {
        &.right {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }
      &.absolute-top {
        &.left {
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
        }
        &.right {
          -webkit-transform: scaleY(-1) scaleX(-1);
          transform: scaleY(-1) scaleX(-1);
        }
      }
    }
    .green--corner--phal {
      $green--corner--phal: 25px;
      width: $green--corner--phal;
      & > div {
        height: $zone-2d--middle-height;
      }
      .phal-cliped- {
        width: 100%;
        padding-right: #{$green--corner--phal - $zone-2d--right-tube-thickness};
      }
      .phal-middle- {
        width: 100%;
        background-color: $green-primary;
        display: flex;
        .phal-phal {
          margin-left: $zone-2d--right-tube-thickness;
          width: 5px;
          height: 100%;
          background-color: $room--2d-light-grey;
        }
      }
      
      &.phal-right {
        position: relative;
        right: -14px;
      }
      .green-100 {
        background-color: $green-primary;
        width: 100%;
        height: 100%;
      }
    }

    /*
      frame styles
    */
    &.--frame-type-1 {
      /*
      used for style 1 and style 2
    */
      .zone-2d-under-tube-right-exceed-last-zone {
        display: none;
      }
      .zone-2d--middle {
        .zone-2d--middle-content {
          .zone-2d-row-wrapper {
            .zone-2d-under-tube {
              padding-left: 17px;
              height: $zone-2d--middle-height;
              display: flex;
              justify-content: flex-end;
              position: relative;
              .zone-2d-under-tube-sub {
                background-color: $room--2d-light-grey;
                flex: 1;
                height: 100%;

                position: relative;
              }
              .zone-2d-under-tube-right-exceed {
                top: 0;
                right: #{-$room-2d-wrapper-padding};
                width: $room-2d-wrapper-padding;
                height: 100%;
                position: absolute;
                background-color: $room--2d-light-grey;
                .zone-2d-under-tube-right-exceed-last-zone {
                  width: $zone-2d--right-tube-thickness;
                  background-color: $white;
                  position: absolute;
                  right: -2px;
                  top: 100%;
                }
              }
              .zone-2d-under-tube-right-exceed-sq {
                top: -5px;
                right: -19px;
                width: 13px;
                height: calc(100% + 10px);
                position: absolute;
                background-color: $green-primary;
              }
              .zone-2d-under-tube-left {
                position: relative;
                width: 10px;
                height: 100%;
                img {
                  position: absolute;
                  bottom: 0;
                  left: 1px;
                  width: 11px;
                }
              }
            }
          }
        }
      }
      & > .zone-2d-square:last-child {
        .zone-2d-under-tube-right-exceed-last-zone {
          display: initial;
          position: relative;
          .right-exceed-last-zone {
            position: absolute;
            right: 0;
            width: $zone-2d--middle-height;
            background-color: $green-primary;
          }
        }
      }
    }
    &.--frame-type-3 {
      /*
      used for style 3 and style 4
    */
      .zone-2d-square {
        height: $zone-2d-overall-height-st3;
      } 
      .zone-2d-under-tube-right-exceed-last-zone {
        display: none;
      }
      .zone-2d--middle {
        .zone-2d--middle-content {
          .zone-2d-row-wrapper {
            .zone-2d-under-tube {
              
              height: #{$zone-2d--middle-height};
              display: flex;
              justify-content: flex-end;
              position: relative;
              .zone-2d-under-tube-sub {
                background-color: transparent;
                flex: 1;
                height: 100%;

                position: relative;
              }
              .zone-2d-under-tube-right-exceed {
                top: 0;
                right: #{(-$room-2d-wrapper-padding) + 5};
                width: #{$room-2d-wrapper-padding + 10};
                height: $zone-2d--right-tube-thickness;
                position: absolute;
                // background-color: $room--2d-light-grey;
                background-color: transparent;
                .zone-2d-under-tube-right-exceed-last-zone {
                  width: $zone-2d--right-tube-thickness;
                  background-color: $white;
                  position: absolute;
                  right: -7px;
                  top: calc(100% - 2px);
                }
              }
              .zone-2d-under-tube-right-exceed-sq {
                top: -5px;
                right: -19px;
                width: 13px;
                height: calc(100% + 10px);
                position: absolute;
                background-color: $green-primary;
              }
              .zone-2d-under-tube-right-exceed-link {
                top: -2px;
                right: -31px;
                width: 13px;
                height: $zone-2d--right-tube-thickness;
                position: absolute;
                background-color: $room--2d-light-grey;
              }
              .zone-2d-under-tube-left {
                position: relative;
                width: 100%;
                height: 100%;
                .right-exceed-last-zone {
                  position: absolute;
                  width: 100%;
                  height: $zone-2d-overall-height-st3;
                  top: #{-($zone-2d-overall-height-st3 / 2 - 3)};
                  padding: 5px;
                  .right-exceed-border {
                    width: 100%;
                    height: 100%;
                    border: $zone-2d--right-tube-thickness solid $room--2d-light-grey;
                    
                  }
                }
              }
            }
          }
        }
      }
      & > .zone-2d-square:last-child {
        .zone-2d-under-tube-right-exceed-last-zone {
          display: initial;
          position: relative;
          .right-exceed-last-zone {
            position: absolute;
            right: 0;
            width: $zone-2d--middle-height;
            background-color: $green-primary;
          }
        }
      }
    }
    &.--frame-type-5 {
      /*
      used for style 5 
    */
      .zone-2d-square {
        height: $zone-2d-overall-height-st5;
      } 
      .zone-2d-under-tube-right-exceed-last-zone {
        display: none;
      }
      .zone-2d--middle {
        .zone-2d--middle-content {
          .zone-2d-row-wrapper {
            .zone-2d-under-tube {
              
              height: #{$zone-2d--middle-height};
              display: flex;
              justify-content: flex-end;
              position: relative;
              .zone-2d-under-tube-sub {
                background-color: transparent;
                flex: 1;
                height: 100%;

                position: relative;
              }
              .zone-2d-under-tube-right-exceed {
                top: 0;
                right: #{(-$room-2d-wrapper-padding) + 5};
                width: #{$room-2d-wrapper-padding + 10};
                height: $zone-2d--right-tube-thickness;
                position: absolute;
                // background-color: $room--2d-light-grey;
                background-color: transparent;
                .zone-2d-under-tube-right-exceed-last-zone {
                  width: $zone-2d--right-tube-thickness;
                  background-color: $white;
                  position: absolute;
                  right: -7px;
                  top: calc(100% - 2px);
                }
              }
              .zone-2d-under-tube-right-exceed-sq {
                top: -5px;
                right: -19px;
                width: 13px;
                height: calc(100% + 10px);
                position: absolute;
                background-color: $green-primary;
              }
              .zone-2d-under-tube-right-exceed-link {
                top: -2px;
                right: -31px;
                width: 13px;
                height: $zone-2d--right-tube-thickness;
                position: absolute;
                background-color: $room--2d-light-grey;
              }
              .zone-2d-under-tube-left {
                position: relative;
                width: 100%;
                height: 100%;
                .right-exceed-last-zone {
                  position: absolute;
                  width: 100%;
                  height: $zone-2d-overall-height-st5;
                  top: #{-($zone-2d-overall-height-st5 / 2 - 3)};
                  padding: 5px;
                  .right-exceed-border {
                    width: 100%;
                    height: 100%;
                    border: $zone-2d--right-tube-thickness solid $room--2d-light-grey;
                    
                  }
                }
              }
            }
          }
        }
      }
      & > .zone-2d-square:last-child {
        .zone-2d-under-tube-right-exceed-last-zone {
          display: initial;
          position: relative;
          .right-exceed-last-zone {
            position: absolute;
            right: 0;
            width: $zone-2d--middle-height;
            background-color: $green-primary;
          }
        }
      }

      .zone-2d--half {
        width: 100%;
        height: 50%;
        position: relative;
        .frame-5-structure {
          position: absolute;
          bottom: 0;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
