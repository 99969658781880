/*------------------------------------*\
  #GLOBAL COLORS
\*------------------------------------*/
$white: #ffffff;
$light-grey: #a7a8a9;
$grey: #76777a;
$dark-grey: #3c3c3c;
$idle-grey: #707070;
$light-grey-sel: #DEDEDE;
$light-grey2: #D3D3D3;
$very-light-grey: #E6E6E6;
$pale-yellow: #EFEFEF;

$gold: #cc9f53;
$thin-yellow: #EAEAEA;
$red-alert: #F75959;

$green-primary: #487629;
$green-success: #39b54a;

$blue-constrast: #00abc8;
$dark-blue: #0475BB;

$background-color: #F8F8F8;
$title-color: #707070;
$input-color: #707070;
$input-background: #dedede;
$font-color: #ffffff;
$grey: #aaaaaa;

$secondary-button: #aaaaaa;

/*------------------------------------*\
#GLOBAL FONTS
\*------------------------------------*/

$sf-compact-display: "SF Compact Display";
$latto: "Lato";
$helvetica-neue: "HelveticaNeue";
$font-awesome-free: "Font Awesome 5 Free";
$helvetica-neue-bold: "Helvetica Neue Bold";
$Montserrat: "Montserrat";

/*------------------------------------*\
  #GLOBAL MARGINS
\*------------------------------------*/

$margin-bottom-title: 50px;
$margin-top-button: 40px;

$semibold: 600;

$header-height: 79px;

/*------------------------------------*\
  #GLOBAL MIXIN
\*------------------------------------*/

@mixin flex-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//svgs
@mixin filled-check-circle($thisColor, $thisFill) {
  svg {
    path {
      stroke: $thisColor;
    }
    circle {
      &[data-name="arrow"] {
        stroke: $thisColor;
      }
      &[data-name="bg"] {
        fill: $thisFill;
      }
    }
  }
}
.frame-zoom-icon {
  &::after {
    content: url(./../assets/svg/frame-zoom.svg);
  }
}
.fullscreen-icon {
  &::after {
    content: url(./../assets/svg/fullscreen.svg);
  }
}
.refresh-icon {
  &::after {
    content: url(./../assets/svg/refresh.svg);
  }
}
.zoom-in-icon {
  &::after {
    content: url(./../assets/svg/zoom-in.svg);
  }
}
.zoom-out-icon {
  &::after {
    content: url(./../assets/svg/zoom-out.svg);
  }
}

//mixin sizes
@mixin one-sized {
  &.custom-input  {
    width: 59px!important;
    text-align: center!important;
    min-width: unset!important;
    padding-left: 10px!important;
    padding-right: 5px!important;
  }
}

//mixin font styles
@mixin generic-latto-16 {
  font-family: $latto;
  font-size: 16px;
  line-height: 19px;
}
@mixin generic-montserrat-16 {
  font-family: $Montserrat;
  font-size: 16px;
  line-height: 19px;
}

