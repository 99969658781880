@import "./../../../styles/global.scss";

.pair-selection {
    @include flex-all;
  width: 152px;
  height: 40px;
  background-color: $thin-yellow;
  .pair-half {
    width: 50%;
    height: 100%;
    font-family: $latto;
    font-size: 12px;
    color: black;
    @include flex-all;
    &.item-selected {
      background-color: $light-grey2;
    }
  }
}
