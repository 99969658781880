@import "./../../../styles/global.scss";

.my-dropdown {
  padding-top: 30px;
  .my-dropdown-inner {
    $this-variant-height: 50px;

    min-width: 100px;
    border-radius: 5px;

    height: $this-variant-height;
    background-color: $white;

    @include flex-start;
    .dropdown-left {
      @include flex-all;
      padding: 0 30px;
      font-family: $Montserrat;
      font-size: 18px;
      color: black;
    }
    .dropdown-right {
      padding-right: 1px;
      @include flex-all;
      height: $this-variant-height;
      .dropdown-right-inner {
        @include flex-all;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: $green-primary;
        width: calc(#{$this-variant-height} - 2px);
        height: calc(#{$this-variant-height} - 2px);
      }
    }
    .dropdown-toggle {
      padding: 0!important;
      &.btn-primary {
        background-color: unset!important;
        border-color: unset!important;
      }
      &.btn-success {
        background-color: unset!important;
        border-color: unset!important;
      }
    }
    .dropdown-toggle::after {
      display: none!important;
    }
  }
  &.variant-2 {
    .my-dropdown-inner {
      $this-variant-height: 37px;
      height: $this-variant-height;
      .dropdown-left {
        @include generic-latto-16;
      }
      .dropdown-right {
        height: $this-variant-height;
        .dropdown-right-inner {
          width: calc(#{$this-variant-height} - 2px);
          height: calc(#{$this-variant-height} - 2px);
        }
      }
    }
  }
}
.my-app-dropdown-options {
  &> a {
    @include generic-latto-16;
  }
}