@import "./../../../../styles/global.scss";

.header {
  .header-content {
    $my-height: $header-height;
    width: 100%;
    display: flex;
    height: $my-height;
    background-color: $green-primary;

    .back-button {
      @include flex-all;
      width: $my-height;
      height: $my-height;
      cursor: pointer;
      svg {
        width: 26px;
        height: 26px;
        path {
          stroke: $white;
          stroke-width: 3px;
        }
      }
    }
    .header-logo {
      display: flex;
      align-items: center;
    }
    .header-text {
      color: white;
      font-size: 16px;
      font-family: $latto;
      font-weight: bold;
    }
    .header-right {
      flex: 1;
    }
  }
}
