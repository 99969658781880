@import "./../../../styles/global.scss";

.nav-sub-menu {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 74px;
  .nav-item {
    min-width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 3px;

    .nav-sub-item {
      @include flex-between;
      padding-right: 7px;
      width: 100%;
      background-color: $green-primary;
      width: 100%;
      height: 100%;
      padding-left: 20px;
      display: flex;
      align-items: center;
      color: $font-color;
      font-family: $sf-compact-display;
      .nav-step {
        font-size: 12px;
        font-family: $sf-compact-display;
        font-weight: $semibold;
      }
      .nav-label {
        font-size: 24px;
        font-weight: $semibold;
        font-family: $sf-compact-display;
      }
      .nav-icon {
        @include filled-check-circle($white, $green-primary);
        &.icon-filled {
          @include filled-check-circle($green-primary, $white)
        }
      }
    }
    &.nav-item-left {
      .nav-sub-item {
        padding-left: 47px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        background-color: $grey;
        border-left: 27px solid $background-color;
        border-top: 37px solid transparent;
        border-bottom: 37px solid transparent;
      }
    }
    &.nav-item-right {
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        background-color: $background-color;
        border-left: 27px solid $grey;
        border-top: 37px solid transparent;
        border-bottom: 37px solid transparent;
      }
    }
    &.is-selected {
      .nav-sub-item {
        background-color: $background-color;
        color: black;
      }

      &.nav-item-left {
        &::before {
          background-color: $green-primary;
        }
      }
      &.nav-item-right {
        &::after {
          border-left: 27px solid $green-primary;
        }
      }
      .nav-icon {
        @include filled-check-circle(black, transparent);
      }
    }
  }

  & > .nav-item:last-child {
    padding-right: 0;
  }
}
