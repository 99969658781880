@import "./../../../styles/global.scss";

/* General */
.kitbuilder {
  background: $background-color;
  .kitbuilder-main-wrapper {
    background: $background-color;
    margin-top: 3px;
    min-height: 760px;
    display: flex;
    flex-direction: column;
    .kit-content {
      .plant-kit-subtitle {
        padding-top: 36px;
      }
    }
    .kit-irrigation {
      padding: 0 14%;
    }
  }

  .step-main-container {
    display: flex;
    flex: 1;
    justify-content: center;
    border-radius: 3px;
  }

  .step-content {
    @include flex-all;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 90px;
  }
  .step-content .step-title {
    margin-bottom: $margin-bottom-title;
  }

  .step-content .custom-button {
    margin-top: $margin-top-button;
  }

  .step-title {
    text-align: center;
    margin: 0px;
    color: $title-color;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    font-family: $helvetica-neue;
  }

  /* KitSelector */

  .kit-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .kit-container {
    p {
      text-align: center;
      margin: 0px;
      margin-block-start: 0px;
      margin-block-end: 0px;
      font-family: $helvetica-neue;
    }
  }
  
  /* Kit Room */
  .kit-room-content {
    .custom-input {
      margin-bottom: 10px;
    }
  }

  .step-subtitle {
    color: $green-primary;
    font-size: 24px;
    font-weight: normal;
    line-height: 48px;
    margin: 0px;
    font-family: $helvetica-neue;
  }

  /* Kit Irrigation */
  .irrigation-kit-container {
    @include flex-all;
    flex-wrap: wrap;
    max-width: 1300px;
    font-family: $helvetica-neue;
    .irrigation-kit {
      @include flex-all;
      background: $green-primary;
      color: white;
      width: 323px;
      height: 150px;
      margin: 0 12.5px;
      margin-bottom: 25px;
      cursor: pointer;
      border-radius: 3px;
      p {
        padding: 45px;
        font-size: 24px;
        color: white;
        text-align: center;
      }
    }
  }

  .kit-room-import {
    .custom-button {
      margin-top: 0;
    }
  }
  .kit-option-input {
    justify-content: center;
  }

}